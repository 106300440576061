*,
*::before,
*::after {
    box-sizing: border-box; // 1
}

*[hidden] {
    display: none !important;
}

html {
    height: 100%;
    font-family: $font-family-sans-serif, sans-serif;
    line-height: 1.15; // 3
    -webkit-text-size-adjust: 100%; // 4
    -ms-text-size-adjust: 100%; // 4
    -ms-overflow-style: scrollbar; // 5
    // -webkit-tap-highlight-color: rgba($black, 0); // 6
}


body {
    min-height: 100%;
    margin: 0; // 1
    font-family: 'Poppins';
    color: #fff;
    // font-family: $font-family-base;
    // font-size: $font-size-base;
    // font-weight: $font-weight-base;
    // line-height: $line-height-base;
    // color: $body-color;
    text-align: left; // 3
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}


html.login .mat-app-background {
    // background-color: #d6d8dc;
    background-color: linear-gradient(360deg, rgb(36 41 57), rgb(24 28 37 / 80%));
}


[tabindex="-1"]:focus {
    outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    color: #fff;
}

a {
    color: $link-color;
    text-decoration: none;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
}

.break-word {
    word-break: break-all;
}

.card-row {
    margin-left: $grid-gutter-width * -1.5;
    margin-right: $grid-gutter-width * -1.5;
}

.card-row-only {
    margin-top: $grid-gutter-width * -1 !important;
    margin-bottom: $grid-gutter-width * -1 !important;

    table {
        margin-bottom: 0;
        border-radius: 5px;
    }
}

.portrait {
    display: block;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.2) center center no-repeat;
    background-size: cover;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    width: 64px;
    height: 64px;

    &.xs {
        width: 32px;
        height: 32px;
    }

    &.lg {
        width: 128px;
        height: 128px;
    }
}


ul.tree-folder,
ul.tree-content {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    height: 0;

    li {
        padding-left: 24px;
    }

    &.open {
        opacity: 1;
        height: auto;
        transition: height 0s linear, opacity .3s $easeOutQuart .1s, transform .3s $easeOutQuart .1s;
    }
}

ul.tree-folder--base {
    &>li {
        padding-left: 0;
    }
}

.row.nowrap {
    flex-wrap: nowrap;
}

.dark,
.light {
    a.inherit {
        color: inherit;
    }

    h1 {
        // font-family: 'Glacial Indifference', sans-serif;
        /* text-transform: uppercase; */
        line-height: 1em;
        font-weight: 400;
        margin-bottom: 0;

        // margin-bottom: 1rem;

        @media (min-width: 1651px) {
            font-size: 2.5rem;
        }

        @media (min-width: 768px) and (max-width: 1366px) {
            font-size: 1.8rem;
        }

        @media (min-width: 1367px) and (max-width: 1650px) {
            font-size: 2rem;
        }
    }

    p {
        font-size: 1rem;

        @media (min-width: 768px) and (max-width: 1366px) {
            font-size: 0.7rem;
        }

        @media (min-width: 1367px) and (max-width: 1650px) {
            font-size: 0.8rem;
        }
    }

    .small {
        font-size: 0.5em;
        line-height: 1em;
    }

    .font-lh-1 {
        line-height: 1em;
    }

    .font-condensed {
        font-family: 'FC Sans Cond', sans-serif;
    }

    .navbar {
        min-height: auto;
        padding: 0 1rem;
        // background: $nav-background !important;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    }

    main .navbar {
        position: fixed;
        left: 0;
        right: 0;
    }

    app-sub-nav {
        display: block;
        height: 50px;
    }

    .navbar-nav {
        margin: 0;
        font-family: 'Poppins';
        font-size: 16px;
        // font-weight: 700;
        // text-transform: uppercase;
        position: relative;

        a.nav-link {
            position: relative;
            padding: 27px;
            transition: all .3s;
            height: 100%;
            cursor: pointer;

            @media (min-width: 768px) and (max-width: 1366px) {
                padding: 20px 10px;
                font-size: 15px;
            }

            mat-icon {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translate(0, -50%);
                height: 16px;
                width: 4px;
                font-size: 1em;
                opacity: 0.5;

                @media (min-width: 768px) and (max-width: 1366px) {
                    right: 13px;
                    height: 12px;
                }
            }

            &.active {
                background: rgba(0, 0, 0, 0.2);
            }
        }

        .dropdown-menu {
            pointer-events: none;
            display: block;
            opacity: 0;
            transform: translate(0, -5px);
            transition: all .3s ease-out;
            margin: 0;
            padding: 0;
            border: 1px solid rgba(237, 242, 255, 0.1);
            border-radius: 8px;
            z-index: -1;

            a {
                font-weight: 700;
                color: #615B8E;
                padding: 0.35rem 16px;
                transition: all .3s ease-out;
                border-radius: 8px;
                cursor: pointer;

                i {
                    color: $pink;
                    font-size: 1.2em;
                }
            }
        }

        .nav-item {
            z-index: +1;

            &:hover {
                .dropdown-menu {
                    pointer-events: all;
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }
        }

        .dropdown a.nav-link {

            // padding-right: 20px;
            @media (min-width: 768px) and (max-width: 1366px) {
                padding-right: 15px;
            }
        }
    }

    /* Material Changes */
    mat-chip.mat-standard-chip,
    .mat-standard-chip {
        background: mat-color($app-primary, 200);

        &.narrow {
            padding: 7px 10px;
        }
    }

    mat-chip.mat-small-chip,
    .mat-small-chip {
        display: inline-block;
        min-height: 24px;
        padding: 4px 9px 3px;
        font-size: .8em;
        border-radius: 15px;

        &.narrow {
            padding: 4px 7px 3px;
        }
    }

    button.d-block {
        display: block;
        width: 100%;
        text-align: left;
    }

    .mat-button,
    .mat-flat-button,
    .mat-icon-button,
    .mat-stroked-button {
        &.inline {
            padding: 0;
            min-width: 0;
            line-height: inherit;
            color: inherit;
            display: block;
        }
    }

    .mat-tooltip {
        font-size: $font-size-base;
    }

    .tooltip {
        padding: 10px 15px;
        font-size: $font-size-base;
        line-height: 1.5;
    }

    .icon-small {
        @include mat-icon-button-size(16px);
    }

    .icon-smaller {
        @include mat-icon-button-size(13px);
    }

    .button-small {
        height: 24px;
        line-height: 24px;
        padding: 0;
        min-width: 24px;
    }

    .absolute-center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .hover {
        .hover-details {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
        }

        .hover-view {
            opacity: 0;
            pointer-events: none;
            transform: translate(-10px, 0);
            transition: all .4s $easeOutCubic;
        }

        .hover-view-zoom {
            transform: scale(1.3);
        }

        &.hover-underline,
        .hover-underline {
            box-shadow: 0;
            transition: all .4s $easeOutCubic;
        }

        &:hover {

            // &.hover-underline,
            // .hover-underline {
            //   box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
            // }

            .hover-view {
                opacity: 1;
                transform: translate(0, 0);
                pointer-events: all;
            }
        }
    }

    button.th-button {
        padding: 4px 4px;
        min-width: auto;
        line-height: 20px;
        border-radius: 100%;
        position: absolute;
        transform: translate(-50%, -50%);
    }


}

.mat-chip-list-wrapper {
    justify-content: center;
}

.mat-chip-list.align-start {
    .mat-chip-list-wrapper {
        justify-content: flex-start;
    }
}

.mat-tooltip {
    white-space: pre-line;
}


.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}


.nav-team {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    z-index: 20;
    pointer-events: none;
    font-family: 'FC Sans Cond', sans-serif;
    font-size: 16px;
    text-transform: uppercase;

    b {
        font-weight: 700;
    }

    .nav-team--backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 0;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.3s linear, height 0s linear 0.31s;
    }

    .nav-team--content {
        position: fixed;
        top: 72px;
        left: 0;
        right: 0;
        padding-top: 30px;
        opacity: 0;
        transform: translate(0, -100%);
        transition: opacity 0.3s linear, transform 0.5s $easeOutQuart;
        box-shadow: 0 0 10px black;

        a {
            transition: all .3s;
        }
    }

    .fadeIn {
        opacity: 0;
        transform: translate(0, -10px);
        transition: all 0s linear .3s;
    }

    .row.nowrap {
        flex-wrap: nowrap;
    }

    .wrap {
        white-space: normal;
        line-height: 1.3em;
        text-align: left;
    }

    &.view {
        pointer-events: all;

        .nav-team--backdrop {
            opacity: 1;
            height: 100vh;
            transition: height 0s linear, opacity 0.3s ease-out 0.1s;
        }

        .nav-team--content {
            transform: translate(0, 0);
            opacity: 1;
        }

        .fadeIn {
            opacity: 1;
            transform: translate(0, 0);
            transition: all .3s $easeOutQuart;

            @for $i from 0 through 40 {
                &.delay#{$i} {
                    transition-delay: 300ms + $i * 20ms;
                }
            }
        }
    }
}

@media screen {
    .dark {
        @import "theme_dark";
    }

    .light {
        @import "theme_light";
    }
}

@media print {
    @import "theme_light";
}

.badge-yellow {
    background-color: #FFEB3B;
    color: #000000;
}

.uni-container {
    // padding-top: 90px;
    padding-left: 0;
    /* margin-bottom: 55px; */
    margin-top: 90px;
}

.navbar {
    z-index: 21;
}

.container {
    max-width: 85%;
    padding-top: 2rem;
    padding-bottom: 4rem;
}

.full-width-table-container {
    min-width: 96.5vw !important;
    height: auto !important;
    margin-left: -7vw !important;
}

form label {
    font-weight: 600;
}


.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
    line-height: 2em !important;
    height: 2em !important;
}

.inline {
    display: inline;
    width: auto !important;
    flex: 0 0 auto;
}


.dropzone {
    .dz-message {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 20px 10px !important;
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: $primary !important;
        border-radius: 4px;
    }

    .dz-text {
        position: relative !important;
        transform: none !important;
    }

    .dz-preview.dz-image-preview {
        background-color: transparent !important;
    }
}

.text-large {
    font-size: 19px;
}

.mat-dialog-container {
    height: auto !important;
}

.font-arial {
    font-family: Arial
}

mat-chip-list.elements-center>div {
    justify-content: center !important;
}

.manager {
    background: url('/assets/manager-wallpaper.png');
    background-attachment: fixed;
    background-size: cover;
}

.manager-light {
    background: url('/assets/manager-wallpaper-white.png');
    background-attachment: fixed;
    background-size: cover;
}

.outline-none {

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }
}

.poppins {
    font-family: 'Poppins';
}

.poppins-light {
    font-family: 'Poppins Light';
}

// .poppins-bold {
//   font-family: 'Poppins Bold';
// }

.poppins-bold {
    font-family: 'Poppins';
    font-weight: 600 !important;
}

.glacial {
    font-family: 'Glacial Indifference';
}


.glacial-bold {
    font-family: 'Glacial Indifference Bold';
}

.core-info-card {
    width: 220px;
    height: 320px;
    border-radius: 8px;
    overflow: hidden;
}

.bg-pink {
    background-color: $pink !important;
}

// .bg-transparent {
//     background-color: transparent !important;
//     border: 1px solid #fff !important;
//     color: #fff;
// }

.st-title {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
        letter-spacing: 1px;
        margin: 0;
        font-family: 'Poppins';
    }

    span {
        font-family: 'Poppins Light';
        font-size: 0.9em;
        font-weight: 400;
        opacity: 0.7;
    }
}

.un-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.un-scroll::-webkit-scrollbar {
    height: 4px;
    width: 8px;
}

.un-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.pd-l-80 {
    padding-left: 80px;

    @media (max-width: 767px) {
        padding-left: 0px;
    }

    @media (min-width: 768px) and (max-width: 1366px) {
        padding-left: 20px;
    }

    @media (min-width: 1367px) and (max-width: 1600px) {
        padding-left: 40px;
    }

    @media (min-width: 1601px) {
        padding-left: 60px;
    }
}

.gray-card-bg {
    background: #181C25;
}

.small-control {
    width: 28px;
    height: 28px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    outline: none;
    font-size: 12px;
}

.gradient-border {
    --border-width: 2px;
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    background: #222;
    border-radius: var(--border-width);

    &::after {
        position: absolute;
        content: "";
        top: calc(-1 * var(--border-width));
        left: calc(-1 * var(--border-width));
        z-index: -1;
        width: calc(100% + var(--border-width) * 2);
        height: calc(100% + var(--border-width) * 2);
        background: linear-gradient(60deg,
                hsl(224, 85%, 66%),
                hsl(269, 85%, 66%),
                hsl(314, 85%, 66%),
                hsl(359, 85%, 66%),
                hsl(44, 85%, 66%),
                hsl(89, 85%, 66%),
                hsl(134, 85%, 66%),
                hsl(179, 85%, 66%));
        background-size: 300% 300%;
        background-position: 0 50%;
        border-radius: calc(2 * var(--border-width));
        animation: moveGradient 4s alternate infinite;
    }
}

@keyframes moveGradient {
    50% {
        background-position: 100% 50%;
    }
}

.main-card {
    position: relative;
    background: linear-gradient(180deg, rgba(154, 146, 220, 0.16) 0%, rgba(218, 216, 234, 0.0752) 50%, rgba(55, 45, 114, 0.16) 100%);
    border: 1px solid rgba(237, 242, 255, 0.1);
    border-radius: 8px;

    .card-info {
        color: #8070b8;
    }

    .main-card-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        color: #E1DEF5;
    }

    .main-card-wrap-header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 240px;
    }

    .main-card-wrap-filters {
        flex: 1;
        gap: 40px;

        .main-card-wrap-filter {
            display: inline-flex;
            align-items: center;
            gap: 4px;
        }
    }

    .add-item-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        position: absolute;
        width: 32px;
        height: 32px;
        right: 14px;
        top: 19px;
        border: 1px solid #615B8E;
        background: no-repeat;
        color: #FF02D0;
        font-size: 1.2em;
        border-radius: 8px;
    }

}

.un-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #8E91D1;
    border-radius: 10px;
}

.un-scroll::-webkit-scrollbar {
    width: 6px;
}

.un-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #8E91D1;
}