table,
table.table {
  border-collapse: collapse;

  th {
    font-weight: 200;
    border: none;
    vertical-align: middle;

    &.bottom {
      vertical-align: middle;
    }
  }

  thead {
    th {
      font-weight: 400;
    }
  }

  &.table-sm td {
    padding: 5px;
  }

  td {
    border-top: none;
    padding: 0.5rem 0.75rem;

    &.middle {
      vertical-align: middle;
    }

    &.buttons {
      padding: 0;
      vertical-align: middle;
      text-align: center;
    }

    &.interactive {
      color: $pink;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background: rgba($pink, 0.05);
      }
    }

    &.td-chiplist {
      .mat-chip-list-wrapper .mat-standard-chip {
        margin: 0 2px 0 0;
      }
    }
  }

  &.table-striped tr.spacer,
  tr.spacer {
    height: 10px;
    background-color: transparent !important;
  }

  &.table-bordered {

    th,
    td {
      border: none;

      &:last-child {
        border-right: none;
      }
    }
  }

  th, td {
    &.no-border {
      border-right: none !important;
    }
    &.narrow {
      width: 1%;
      white-space: nowrap;
    }
  }

}

.dark {
  .header-bg {
    background-color: #1D3549;
  }
  .selected-row td {
    background-color: #2FB35B;
    background-color: $blue-light;
    color: white !important;
  }
}

.light {
  .header-bg {
    background-color: white;
  }
  .selected-row td {
    background-color: #26A699;
    color: white !important;
  }
}

figure.table {
  margin-left: 0px;
  border-collapse: collapse;
  font-size: 1rem;
  table {
    width: 100%;
  }

  th {
    font-weight: 200;
    border: none;
    vertical-align: middle;
  }

  thead {
    th {
      font-weight: 400;
      padding: 0.3rem;
    }
  }

  td {
    border-top: none;
    padding: 0.3rem;
  }

  th, td {
    border: none;
    &:last-child {
      border-right: none;
    }
  }

}
