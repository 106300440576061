$font-path: '/assets/fonts/fc-sans/';
$font-path-glacial: '/assets/fonts/glacialIndifference/';
$font-path-poppins: '/assets/fonts/poppins/';
$font-path-lato: '/assets/fonts/lato/';


@font-face {
    font-family: 'FC Sans Cond';
    src: url($font-path + 'FCSansCond-Bold.eot');
    src: url($font-path + 'FCSansCond-Bold.eot?#iefix') format('embedded-opentype'),
        url($font-path + 'FCSansCond-Bold.woff2') format('woff2'),
        url($font-path + 'FCSansCond-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FC Sans';
    src: url($font-path + 'FCSans-Bold.eot');
    src: url($font-path + 'FCSans-Bold.eot?#iefix') format('embedded-opentype'),
        url($font-path + 'FCSans-Bold.woff2') format('woff2'),
        url($font-path + 'FCSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FC Sans Cond';
    src: url($font-path + 'FCSansCond-Regular.eot');
    src: url($font-path + 'FCSansCond-Regular.eot?#iefix') format('embedded-opentype'),
        url($font-path + 'FCSansCond-Regular.woff2') format('woff2'),
        url($font-path + 'FCSansCond-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FC Sans';
    src: url($font-path + 'FCSans-Light.eot');
    src: url($font-path + 'FCSans-Light.eot?#iefix') format('embedded-opentype'),
        url($font-path + 'FCSans-Light.woff2') format('woff2'),
        url($font-path + 'FCSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FC Sans';
    src: url($font-path + 'FCSans-SemiBold.eot');
    src: url($font-path + 'FCSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url($font-path + 'FCSans-SemiBold.woff2') format('woff2'),
        url($font-path + 'FCSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'FC Sans';
    src: url($font-path + 'FCSans-Regular.eot');
    src: url($font-path + 'FCSans-Regular.eot?#iefix') format('embedded-opentype'),
        url($font-path + 'FCSans-Regular.woff2') format('woff2'),
        url($font-path + 'FCSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'FontAwesome';
//     src: url($font-path +'fa-regular-400.woff');
//     font-weight: normal;
//     font-style: normal
// }

@font-face {
    font-family: 'Glacial Indifference';
    src: url($font-path-glacial + 'GlacialIndifference-Regular.otf');
    font-weight: normal;
    font-style: normal
}


@font-face {
    font-family: 'Glacial Indifference Bold';
    src: url($font-path-glacial + 'GlacialIndifference-Bold.otf');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Poppins';
    src: url($font-path-poppins + 'Poppins-Regular.ttf');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Poppins Bold';
    src: url($font-path-poppins + 'Poppins-Bold.ttf');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Poppins Light';
    src: url($font-path-poppins + 'Poppins-Light.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url($font-path-lato + 'Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}