.pagination {
  width: 50%;
  float: left;

  a.page-link {
    width: 27px;
    height: 27px;
    text-align: center;
    font-size: 14px;
    border-radius: 0 !important;
    background: transparent;
    padding: 5px 0;
    margin: 0 2px;
    border: none;
    transition: all .4s ease-out;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 1px;
      top: 100%;
      left: 50%;
      width: 0;
      transform: translate(-50%, 0);
      transition: all .3s ease-out;
    }

    &:hover {
      background: transparent;

      &:before {
        width: 100%;
      }
    }
  }

  .active {
    a.page-link {
      background-color: transparent;

      &:before {
        width: 100%;
      }
    }
  }

  .disabled {
    a.page-link {
      background: transparent;

      &:before {
        display: none;
      }
    }
  }

  &.pull-right {
    float: right;
    width: auto;
  }
}
