.cursor-pointer {
    cursor: pointer;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.overflow-hidden {
    overflow: hidden;
}

.text-cut {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-pink {
    color: #FF02D0;
}

.text-mute {
    color: #B6B2D4;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

.flex-1 {
    flex: 1 1 0%;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.items-center {
    align-items: center;
}

.items-start {
    align-items: flex-start;
}

.width-auto {
    width: auto !important;
}

.text-start {
    text-align: start;
}

;

.font-bold {
    font-weight: bold;
}

/*-- spacing utilities --*/
.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pr-5 {
    padding-right: 3rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.pl-5 {
    padding-left: 3rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

@media (min-width: 768px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0 {
        margin-left: 0 !important;
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1 {
        margin-left: 0.25rem !important;
    }

    .mx-sm-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .my-sm-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2 {
        margin-left: 0.5rem !important;
    }

    .mx-sm-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .my-sm-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3 {
        margin-left: 1rem !important;
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4 {
        margin-left: 1.5rem !important;
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5 {
        margin-left: 3rem !important;
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto {
        margin-left: auto !important;
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0 {
        padding-left: 0 !important;
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1 {
        padding-left: 0.25rem !important;
    }

    .px-sm-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .py-sm-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2 {
        padding-left: 0.5rem !important;
    }

    .px-sm-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .py-sm-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3 {
        padding-left: 1rem !important;
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4 {
        padding-left: 1.5rem !important;
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5 {
        padding-left: 3rem !important;
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}

@media (min-width: 992px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0 {
        margin-left: 0 !important;
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1 {
        margin-left: 0.25rem !important;
    }

    .mx-md-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .my-md-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2 {
        margin-left: 0.5rem !important;
    }

    .mx-md-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .my-md-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3 {
        margin-left: 1rem !important;
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4 {
        margin-left: 1.5rem !important;
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5 {
        margin-left: 3rem !important;
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto {
        margin-left: auto !important;
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0 {
        padding-left: 0 !important;
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1 {
        padding-left: 0.25rem !important;
    }

    .px-md-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .py-md-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2 {
        padding-left: 0.5rem !important;
    }

    .px-md-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .py-md-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3 {
        padding-left: 1rem !important;
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4 {
        padding-left: 1.5rem !important;
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5 {
        padding-left: 3rem !important;
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0 {
        margin-left: 0 !important;
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1 {
        margin-left: 0.25rem !important;
    }

    .mx-lg-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .my-lg-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2 {
        margin-left: 0.5rem !important;
    }

    .mx-lg-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .my-lg-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3 {
        margin-left: 1rem !important;
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4 {
        margin-left: 1.5rem !important;
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5 {
        margin-left: 3rem !important;
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto {
        margin-left: auto !important;
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0 {
        padding-left: 0 !important;
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1 {
        padding-left: 0.25rem !important;
    }

    .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2 {
        padding-left: 0.5rem !important;
    }

    .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3 {
        padding-left: 1rem !important;
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4 {
        padding-left: 1.5rem !important;
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5 {
        padding-left: 3rem !important;
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}

.m-6 {
    margin: 6rem !important;
}

.mx-6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
}

.my-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}

.ml-6 {
    margin-left: 6rem !important;
}

.mr-6 {
    margin-right: 6rem !important;
}

.mt-6 {
    margin-top: 6rem !important;
}

.mb-6 {
    margin-bottom: 6rem !important;
}

.p-6 {
    margin: 5rem !important;
}

.px-6 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
}

.py-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.pl-6 {
    margin-left: 5rem !important;
}

.pr-6 {
    margin-right: 5rem !important;
}

.pt-6 {
    margin-top: 5rem !important;
}

.pb-6 {
    margin-bottom: 5rem !important;
}

.nowrap {
    white-space: nowrap;
}

.select-none {
    user-select: none;
}

.font-weight-400 {
    font-weight: 400;
}

.active-hover {
    &:hover {
        color: $pink;
        cursor: pointer;
    }
}

.underline-spacer {
    width: 128px;
    height: 4px;
    left: 0px;
    bottom: 30px;

    background: #FF02D0;
}

.word-break {
    word-break: break-word;
}

.aspect-16 {
    height: 100%;
    aspect-ratio: 16/9
}