@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

$bg-color: radial-gradient(50% 50% at 50% 50%, #4222A5 0%, rgba(40, 45, 191, 0) 100%);
$navbar-bg-color: #242939;
$navbar-color: #6e7899;
$sidebar-bg-color: #181C25;
$sidebar-color: #3d4465;
$sidebar-icon-color: #8E91D1;
$border-color: #f2f3f8;

body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-size: .9rem;
}


.wrapper.default-theme {

    background: $bg-color;

    nav.navbar {
        background: $navbar-bg-color;
        // border-color: rgba($navbar-color, .3);


        .navbar-brand {
            color: lighten($navbar-color, 25%);
        }

        .left-nav {
            input.navbar-search {
                background: #282f48;
                color: $navbar-color;

                &::placeholder {
                    color: rgba($navbar-color, .6);
                }
            }
        }

        .right-nav {
            //   background: $navbar-bg-color;
        }

        .nav-link {
            // color: $navbar-color;

            // i {
            //   border-color: $navbar-color;
            //   background: #282f48;
            // }

            // &:hover {
            //   color: lighten($navbar-color, 15%);
            // }
        }

        .nav-alert {
            border-color: #2f3546;

            &.notifications {
                background-color: #ff61c5;
                box-shadow: 0px 0px 4px #ff61c5;

            }

            &.messages {
                background-color: #61b8ff;
                box-shadow: 0px 0px 4px #61b8ff;
            }

        }
    }

    main {
        .overlay {
            background: #242939;
        }

        .sidebar {
            background: transparent;

            >div {
                border-color: rgba($sidebar-color, .1);
                background: linear-gradient(180deg, rgba(154, 146, 220, 0.16) 0%, rgba(218, 216, 234, 0.0752) 50%, rgba(55, 45, 114, 0.16) 100%);
                border: 1px solid rgba(237, 242, 255, 0.05);
                border-radius: 8px;
                overflow-x: hidden;
            }

            a.sidebar-link {
                color: $sidebar-color;
            }

            .sidebar-profile {
                .name {
                    color: $sidebar-color;
                }

                .role {
                    color: rgba($sidebar-color, .8);
                }
            }

            .sidebar-menu {
                .menu-header {
                    color: rgba($sidebar-color, .5);
                }

                .menu-items {
                    a {
                        font-family: 'Poppins';
                        // color: #FFF;
                        color: #8E91D1;
                        transition: all 0.35s cubic-bezier(0.4, 0, 1, 1);

                        i {
                            color: $sidebar-icon-color;
                            border: none;
                            width: auto;
                            // min-width: 30px;
                            font-size: 1.5rem;
                        }

                        &:hover {
                            // background: #f5f5f9;
                            // color: #FF02D0 !important;
                            background: rgba(237, 242, 255, 0.05);

                            i {
                                // color: #FF02D0;
                            }
                        }

                        &.active {
                            // border-left: 4px solid #FF02D0;
                            background-color: rgba(237, 242, 255, 0.05);
                            color: #fff !important;
                            ;

                            i {
                                color: #fff !important;
                                ;
                            }

                            .active-selector {
                                position: absolute;
                                width: 2px;
                                height: 100%;
                                left: 0px;
                                top: 0px;
                                background: #FF02D0;
                                border-radius: 0px 4px 4px 0px;
                                transition: all 400ms cubic-bezier(.31, 1.21, .64, 1.02);
                            }
                        }
                    }
                }

            }
        }
    }

    .card {
        box-shadow: 1px 1px 2px rgba(#5b6582, .1);

        .card-title {
            color: rgba($sidebar-color, .8);
        }

        .card-icon {
            i {
                color: #98a4c7;
            }
        }

        .card-footer {
            border-top: 1px solid $border-color;
            background: none;
        }

        .card-title {
            border-bottom: 1px solid $border-color;
        }
    }

    // .table {

    //   th,
    //   td {
    //     border-top: 1px solid darken($border-color,2%);
    //   }

    //   thead th {
    //     border-bottom: 2px solid darken($border-color,2%);
    //   }

    //   &.table-striped tbody tr:nth-of-type(odd) {
    //     background-color: lighten($border-color,1%);
    //   }

    //   &.table-hover tbody tr:hover {
    //     background-color: lighten($border-color, 1%);
    //   }
    // }

    .btn {
        font-size: .9rem;

        &.btn-lightning {
            color: #f0f3ff;
            background-color: #5b6582;
            border-color: #5b6582;

            &:hover {
                background-color: #4c5671;
            }
        }

        &.btn-outline-lightning {
            color: #5b6582;
            background-color: transparent;
            border-color: #5b6582;

            &:hover {
                color: #f0f3ff;
                background-color: #5b6582;
                border-color: #5b6582;
            }
        }
    }

    .form-control {
        font-size: .9rem;
    }

    .text-lightning {
        color: #98a4c7;
    }
}