$chartColors: (
    1: #19A188,
    2: #BCBB8F,
    3: #CC4F2F,
    4: #CED26C,
    5: #1B6F7D,
    6: #D7D0A5,
    7: #808080,
);

.mat-app-background {
    // background-color: rgba($secondary-light, 0.05);
    background-color: #0E121A;
}

.app-background {
    background-color: #ffffff;
}

body {
    font-weight: 400;
}

h1,
h2 {
    color: $primary;
}

a {
    // color: $secondary-light;
    color: $pink;

    &:hover {
        color: darken($secondary-light, 10%);
    }
}

.thin {
    font-weight: 300;
}

hr {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.gradient-1 {
    background: linear-gradient(180deg, rgba($nav-background, 0.1), rgba($nav-background, 0));
}

.gradient-2 {
    background: linear-gradient(180deg, rgba($nav-background, 0.2), rgba($nav-background, 0));
}

button.th-button {
    background: mat-color($mat-teal, 300);
    color: #fff;
}


.mat-list .mat-list-item {

    &[color="accent"],
    &.active {
        color: $secondary-light;
    }
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    // color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
}

.mat-optgroup-label {
    background: rgba(0, 0, 0, 0.2);
}

form label {
    font-weight: 400;
}

.label {
    font-size: 11px;
    color: $primary;
    margin-bottom: 0;
}

.mat-chip.mat-standard-chip {
    font-weight: 700;
    color: mat-color($mat-oxford-blue, 900);
}

.bg-blue {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $secondary-light;
    }
}


.bg-dark-green {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: #26A699;
    }
}

.bg-green {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $green-light !important;
    }
}

.bg-yellow {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $yellow-light !important;
    }
}

.bg-orange {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $orange-light !important;
    }
}

.bg-red {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $red-light !important;
    }
}

.bg-card {
    background-color: $card-light;
}

.tag {
    &.red {
        color: white;
        background-color: $red-light;
    }

    &.yellow {
        color: #333333;
        background-color: $yellow-light;
    }

    &.green {
        color: #333333;
        background-color: $green-light;

    }

    &.dark-green {
        color: white;
        background-color: #26A699;
    }

    &.blue {
        color: #333333;
        background-color: $blue-light;
    }

    &.gray {
        color: white;
        background-color: #888888;
    }

    &.orange {
        color: #333333;
        background-color: #FEA26E;
    }
}

.fa-circle {
    &.red {
        color: $red-light;
    }

    &.yellow {
        color: $yellow-light;
    }

    &.green {
        color: $green-light;
    }

    &.dark-green {
        color: #26A699;
    }

    &.blue {
        color: $blue-light;
    }

    &.gray {
        color: #888888;
    }

    &.orange {
        color: #FEA26E;
    }
}

.text-accent {
    color: mat-color($mat-teal, 400);
}

.text-green {
    color: mat-color($mat-teal, 400);
}

.text-red {
    color: $red-light;
}

.text-yellow {
    color: $yellow-light;
}

.text-gray {
    color: #929292;
}

.text-blue-light {
    color: #64aaf5;
}

.bg-dark-green {
    background-color: #26A699;
}


.border-accent {
    border-color: #26A699;
}

.hover-text-blue-light {
    &:hover {
        color: #64aaf5;
    }
}

.border-bottom {
    border-bottom: 1px solid #cccccc !important;
}

table.table {
    th {
        font-weight: 400;
    }

    thead {
        // background: $secondary-light;
        // color: #fff;
        background: rgba($secondary-light, 0);
        border-bottom: 2px solid $secondary-light;
        color: #000;
        font-weight: 900;

        &.mat-calendar-table-header {
            background: #fff;
        }
    }

    td {
        font-weight: 400;
        // color: $primary;
        color: #d4dbe1;

        &.interactive,
        a.interactive {
            color: $pink;
            font-weight: 600;
        }

        &.interactive-off,
        a.interactive-off {
            // color: darken($secondary-light, 50%);
            color: $pink;
        }
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        // background-color: rgba($table-light, 0.4);
        background-color: rgb(14 18 26);
    }

    &.table-striped tbody tr:nth-of-type(even) {
        //   background-color: rgba($table-light, 1);
        background-color: #181c25;
    }

    &.table-bordered {

        th,
        td {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

figure.table {
    th {
        font-weight: 400;
    }

    thead {
        background: rgba($secondary-light, 0);
        border-bottom: 2px solid $secondary-light;
        color: #000;
        font-weight: 900;
    }

    td {
        font-weight: 400;
        color: $primary;
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgba($table-light, 0.4);
    }

    tbody tr:nth-of-type(even) {
        background-color: rgba($table-light, 1);
    }

    th,
    td {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.interactive {
    color: $pink;
    font-weight: 600;
    cursor: pointer;
}

.svg-logo {

    mask,
    path {
        fill: #fff;
    }
}

.nav-team--content {
    color: #fff;
    background: darken($oxford-blue, 5%);

    a {
        color: #fff;

        &:hover,
        &.active {
            color: $secondary-light;
        }

        .portrait {
            transition: background-color .3s ease-out;
        }

        &.active {
            .portrait {
                background-color: #fff;
            }
        }
    }
}

.navbar {
    // background: $nav-background-light !important;
    // background: $oxford-blue !important;
    background: #020D16 !important;
    border-bottom: 2px solid #FF02D0;
    box-shadow: 0px 0px 23.1111px rgba(0, 163, 255, 0.8);

    .mat-input-element {
        color: #fff;
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        color: rgba(255, 255, 255, 1);
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .mat-form-field-ripple,
    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: rgba(255, 255, 255, 1);
    }
}

.navbar-nav {
    a.nav-link {
        // color: $secondary-light;
        color: #fff;

        mat-icon {
            opacity: 0.5;
        }

        &.active,
        &:hover {
            background: rgba(#fff, 0.2);
            // color: $secondary-light;
        }
    }

    .dropdown-menu {
        background: $oxford-blue;
        color: #fff;
        border: none;
        box-shadow: 0 3px 4px rgba(#333, 0.3);

        a {
            color: #fff;
            background: $oxford-blue;

            &:hover,
            &.active {
                background: rgba(255, 255, 255, 0.2);
                color: #fff;
            }
        }
    }

    .nav-item {
        &:hover {
            a.nav-link {
                background: rgba(255, 255, 255, 0.2);
                color: #fff;
            }
        }
    }
}

.manager-light {
    .navbar-nav {
        a.nav-link {
            // color: $secondary-light;
            color: #094f81;

            mat-icon {
                opacity: 0.5;
            }

            &.active,
            &:hover {
                background: #e1edf5;
                // color: $secondary-light;
            }
        }

        .dropdown-menu {
            background: #e1edf5;
            color: #094f81;
            border: none;
            box-shadow: 0 3px 4px rgba(#333, 0.3);

            a {
                color: #094f81;
                background: #e1edf5;

                &:hover,
                &.active {
                    background: #b7c4ce;
                    color: #063351;
                }
            }
        }

        .nav-item {
            &:hover {
                a.nav-link {
                    background: #e1edf5;
                    color: #094f81;
                }
            }
        }
    }
}

.manager-nav {
    background-color: #bacbd5;
}

.scroll {
    background-color: rgba(255, 255, 255, 0.6);
}

.hub-text {
    color: #333333;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    background: $secondary-light;
    color: #fff;
}


.pagination {
    a.page-link {
        color: rgba($pink, 0.5);

        &:before {
            background: #fff;
        }

        &:hover {
            color: $pink;
        }
    }

    .active {
        a.page-link {
            color: $pink;

            &:before {
                background: $pink;
            }
        }
    }

    .disabled {
        a.page-link {
            color: rgba($pink, 0.3);
        }
    }
}

.portrait {
    background-color: rgba(#000, 0.2);
}



.card-event {
    table.table {
        thead {
            // background: $secondary-light;
            // color: #fff;
            background: none;
            border-bottom: none;
            color: #000;
            font-weight: 900;

            tr {
                background: none;
            }

            th {
                padding: 2px 5px;
            }
        }
    }
}

.card-eventtype-participants {
    line-height: 16px;
    // color: rgba(0, 0, 0, 0.5);
}

.bodymap-dark {
    display: none;
}

.copy-bg {
    background: rgba($secondary-light, 0.2);
}

.dropzone {
    .dz-message {
        color: $primary !important;
    }
}

.player-card {
    background: #e1eef4;
    // background: linear-gradient(to bottom, #e1eef4, rgba(0,0,0,0.02));
}

.player-number {
    color: #bbccd6;
}

.player-index-bar {
    &.gray {
        background-color: #808080;
    }

    &.red {
        background-color: #fe5b58;
    }

    &.orange {
        background-color: #fea331;
    }

    &.yellow {
        background-color: #ffff00;
    }

    &.green {
        background-color: #00fe9c;
    }
}

.current-class {
    background-color: #26A699;
}

.tab-link {
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tab-link.active {
    border-bottom: 2px solid #616161;
}

.fade-block {
    background-color: #e1edf4;
}

.radial-chart .percentage {
    fill: #fefefe;
}

.player-index-modal {
    background: linear-gradient(to bottom, #baccd5, #bacbd5);

    .number {
        color: #e1edf4;
    }
}

.tab-link.opened {
    border-bottom: 2px solid #051f36;
    ;
}

.tabs-header {
    background: #e1edf4;
}

.route-btn {
    background-color: #c3d3dd;
    color: #112b40;
}

.tabs-block {
    background: linear-gradient(to bottom, #e1edf4, #e1edf5);
}

.legend {
    fill: #333333 !important;
}

.timeline-primary {
    color: #444444;
}

.pitch-primary {
    color: #64aaf5;
    background-color: #64aaf5;
}

.pitch-secondary {
    color: #f94b47;
    background-color: #f94b47;
}

.hover {
    &:hover {

        &.hover-underline,
        .hover-underline {
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
        }
    }
}

.hub-wp {
    background: url('/assets/wallpaper-white-hub.jpg');
    background-attachment: fixed;
    background-size: cover;
}

.groups-block {
    background: radial-gradient(#fff, transparent);
}

.diagnostics-semi-transparent {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 20%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0) 100%);
}

.divider {
    background-color: #000;
}


.manager-nav-btn {
    height: 40px;
    width: 200px;
    padding: 8px 15px 8px 15px;
    background: transparent;
    border: 1px solid #dddddd;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
}

.manager-nav-btn:hover {
    background-color: #e0e0e0;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}

.manager-nav-btn-active {
    background-color: #e0e0e0;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}

.text-shadow {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.75);
}