.tag {
    border-radius: 9px;
    padding: 2px 8px;
    white-space: nowrap;

    &.tag-xl {
        padding: 4px 10px;
    }

}

.task-chip {
    display: inline-flex;
    align-items: center;
    padding: 4px 10px;
    padding-right: 11px;
    width: auto;
    height: 28px;
    border-radius: 6px;
    border: 1px solid #8E91D1;
    color: #8E91D1;
    font-size: 1em;
    grid-gap: 4px;
    gap: 4px;
}