@use '@angular/material' as mat;


@include mat.core();

$mat-oxford-blue: (
    50: lighten($oxford-blue, 45%),
    100: lighten($oxford-blue, 40%),
    200: lighten($oxford-blue, 35%),
    300: lighten($oxford-blue, 30%),
    400: lighten($oxford-blue, 25%),
    500: lighten($oxford-blue, 20%),
    600: lighten($oxford-blue, 15%),
    700: lighten($oxford-blue, 10%),
    800: lighten($oxford-blue, 5%),
    900: lighten($oxford-blue, 0%),
    A100: #ff8a80,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $light-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$custom-typography: mat.define-typography-config($font-family: $font-family-sans-serif,
        $headline-1: mat-typography-level(14px, 14px, 400),
        $headline-2: mat-typography-level(14px, 14px, 400),
        $headline-3: mat-typography-level(14px, 14px, 400),
        $headline-4: mat-typography-level(14px, 14px, 400),
        $headline-5: mat-typography-level(14px, 14px, 400),
        $headline-6: mat-typography-level(14px, 14px, 400),
        $subtitle-1: mat-typography-level(14px, 14px, 400),
        $subtitle-2: mat-typography-level(14px, 14px, 400),
        $body-1: mat-typography-level(14px, 14px, 400),
        $body-2: mat-typography-level(14px, 14px, 400),
        $caption: mat-typography-level(14px, 14px, 400),
        $button: mat-typography-level(14px, 14px, 400),
        $overline: mat-typography-level(14px, 14px, 400),
    );

$app-primary: mat.define-palette($mat-oxford-blue, 700, 600, 500);
$app-accent: mat.define-palette($mat-light-green, A400);
$app-warn: mat.define-palette($mat-pink, A200);
$light-accent: mat.define-palette($mat-teal, 500);
$app-primary-light: mat.define-palette($mat-gray, 700, 600, 500);


$app-theme-light: mat.define-light-theme((color: (primary: $app-primary-light,
                accent: $light-accent, warn: $app-warn,
            ),
            typography: $custom-typography,
        ));

$app-theme-dark: mat.define-dark-theme((color: (primary: $app-primary,
                accent: $app-accent, warn: $app-warn,
            ),
        ));

@include mat.form-field-density(-2);

@include mat.all-component-themes($app-theme-light);

@media screen {
    .dark {
        @include mat.all-component-colors($app-theme-dark);
    }
}

$nav-link-color: rgba(255, 255, 255, 0.5);
$black: #000000;


$mat-icon-font-size: 12px;
$mat-icon-button-icon-size: 4px;
$mat-mdc-icon-button-icon-size: 4px;

$mat-datepicker-toggle-icon-size: 14px;
$mat-datepicker-toggle-icon-wrapper-size: 24px;

.mat-datepicker-toggle mat-icon {
    font-size: $mat-datepicker-toggle-icon-size;
    height: $mat-datepicker-toggle-icon-size;
    width: $mat-datepicker-toggle-icon-size;
}

.mat-datepicker-toggle svg {
    font-size: $mat-datepicker-toggle-icon-size !important;
    height: $mat-datepicker-toggle-icon-size !important;
    width: $mat-datepicker-toggle-icon-size !important;
    margin-left: auto;
    margin-right: auto;
}

.mat-datepicker-toggle mat-mdc-button-touch-target {
    font-size: $mat-datepicker-toggle-icon-size;
    height: $mat-datepicker-toggle-icon-size;
    width: $mat-datepicker-toggle-icon-size;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    // width: $mat-datepicker-toggle-icon-wrapper-size !important;
    // height: $mat-datepicker-toggle-icon-wrapper-size !important;
    zoom: 0.8 !important;
    padding: 8px;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: $mat-datepicker-toggle-icon-wrapper-size !important;
    width: $mat-datepicker-toggle-icon-wrapper-size !important;
}

@mixin mat-icon-button-size($size: 40px) {
    &.mat-icon {
        width: $size;
        height: $size;
        line-height: $size;
        margin: 0;
    }

    &.material-icons {
        font-size: $size;
    }
}