$chartColors: (
    1: #19A188,
    2: #BCBB8F,
    3: #CC4F2F,
    4: #CED26C,
    5: #1B6F7D,
    6: #D7D0A5,
    7: #808080,
);

h1,
h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;

    text-transform: uppercase;
    background: -webkit-linear-gradient(0deg, #CF88C2 100%, #966FFF 100%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 4px transparent;
    color: #211158;
    text-transform: uppercase;
}

hr {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

a {
    // color: $secondary-light;
    // color: $pink;

    &:hover {
        color: #fff;
    }
}

.thin {
    font-weight: 300;
    opacity: 0.5;
}

.gradient-1 {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
}

.gradient-2 {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
}

button.th-button {
    background: rgba(255, 255, 255, 0.5);
}

.mat-app-background {
    background-color: $background;
}

.app-background {
    background-color: $background;
}


.mat-paginator {
    background-color: $card;
}

.mat-dialog-container {
    background: $card;

    // background: rgba(67, 34, 165, 1.0);
    // background: radial-gradient(at center, rgba(67, 34, 165, 1.0), rgba(8, 5, 31, 1.0));
    // border-radius: 12px;


}

.dialog-container-projects .mat-dialog-container {
    background: url($modal-background-image-url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid $pink !important;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 4px $pink;
}

.mat-datepicker-content {
    background: mat-color($app-primary, 800);
}

.mat-list .mat-list-item {

    &[color="accent"],
    &.active {
        color: mat-color($app-accent);
    }
}

.mat-select-panel {
    background: mat-color($app-primary, 700);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(255, 255, 255, 0.3);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color($app-accent);
}

.mat-form-field {
    &.mat-focused {
        .mat-form-field-label {
            color: mat-color($app-accent);
        }

        .mat-form-field-ripple {
            background-color: mat-color($app-accent);
        }
    }
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 300;
}

.mat-optgroup-label {
    background: rgba(0, 0, 0, 0.2);
}

.mat-radio-outer-circle {
    border-color: rgba(255, 255, 255, 0.2);
}

.mat-button.mat-primary {
    color: #fff;
}

.mat-calendar-body-selected {
    background: $oxford-blue;
    color: mat-color($app-accent);
}

.mat-input-element {
    caret-color: #dddddd;
}

form label {
    font-weight: 400;
}

.label {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
}

.mat-tooltip {
    background: #fff;
    color: $oxford-blue;
}

.bg-blue {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $secondary-light;
    }
}

.bg-dark-green {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: #1e733a;
    }
}

.bg-green {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $green !important;
    }
}

.bg-yellow {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $yellow !important;
    }
}

.bg-orange {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $orange !important;
    }
}

.bg-red {

    &,
    &.mat-chip {
        color: mat-color($mat-oxford-blue, 900);
        font-weight: 700;
        background-color: $red !important;
    }
}

.bg-card {
    background-color: $card;
}


.tag {
    &.red {
        color: white;
        background-color: $red;
    }

    &.yellow {
        color: #000000;
        background-color: $yellow;
    }

    &.green {
        color: white;
        background-color: #2FB35B;
    }

    &.dark-green {
        color: white;
        background-color: #1e733a;
    }

    &.blue {
        color: #000000;
        background-color: #00A3FF;
    }

    &.gray {
        color: white;
        background-color: #404655;
    }

    &.orange {
        color: #000000;
        background-color: #FF9900;
    }

    &.pink {
        color: #000000;
        background-color: #FF02D0;
    }

    &.violet {
        color: #000000;
        background-color: #7B61FF;
    }

}

.fa-circle {
    &.red {
        color: $red;
    }

    &.yellow {
        color: $yellow;
    }

    &.green {
        color: #2FB35B;
    }

    &.dark-green {
        color: #2FB35B;
    }

    &.blue {
        color: $blue-light;
    }

    &.gray {
        color: #888888;
    }

    &.orange {
        color: #FEA26E;
    }
}

.text-accent {
    color: $secondary;
}

.text-green {
    // color: $green;
    color: #2FB35B;
}

.text-red {
    color: $red;
}

.text-yellow {
    color: $yellow-light;
}

.text-gray {
    color: #929292;
}

.text-blue-light {
    color: $blue-light;
}

.hover-text-blue-light {
    &:hover {
        color: $blue-light;
    }
}

.bg-dark-green {
    background-color: #2FB35B;
}

.border-accent {
    border-color: #2FB35B;
}

.border-bottom {
    border-bottom: 1px solid #676767 !important;
}

table.table {
    thead {
        tr {
            // background: rgba(255, 255, 255, 0.2);
            color: #fff;
        }

        th {
            color: #B6B2D4;
        }
    }

    td {
        color: rgba(255, 255, 255, 0.7);

        a.interactive,
        &.interactive {
            color: $pink;
        }

        &.interactive-off,
        a.interactive-off {
            color: darken($pink, 50%);
        }

        &.table-light {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.04);
    }

    &.table-striped tbody tr:nth-of-type(even) {
        background-color: rgba(255, 255, 255, 0.07);
    }

    &.table-bordered {

        th,
        td {
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}

table.slim {
    thead {
        tr {
            background: transparent;
            color: #fff;
            border-top: 2px solid #777a7e;
            border-bottom: 2px solid #777a7e;
        }

        th {
            color: #777a7e;
            border-bottom: 2px solid #777a7e;
            font-size: 1.2em;
            font-weight: 400;
            padding-left: 18px !important;
            padding-right: 18px !important;

            &:not(:last-child) {
                border-right: 1px solid;
            }
        }
    }

    tbody tr {
        border-bottom: 1px solid #777a7e;
    }

    td {
        color: #fff;
        padding-left: 18px !important;
        padding-right: 18px !important;
        font-size: 0.8em;
        padding-top: 8px !important;
        padding-bottom: 8px !important;

        &:not(:last-child) {
            border-right: 1px solid #777a7e;
        }

        a.interactive,
        &.interactive {
            color: $pink;
        }

        &.interactive-off,
        a.interactive-off {
            color: darken($pink, 50%);
        }

        &.table-light {
            background: transparent;
        }
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: transparent;
    }

    &.table-striped tbody tr:nth-of-type(even) {
        background-color: transparent;
    }

    &.table-bordered {

        th,
        td {
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}

figure.table {
    thead {
        tr {
            background: rgba(255, 255, 255, 0.2);
            color: #fff;
        }

        th {
            color: #fff;
        }
    }

    td {
        color: rgba(255, 255, 255, 0.7);
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.04);
    }

    tbody tr:nth-of-type(even) {
        background-color: rgba(255, 255, 255, 0.07);
    }

    th,
    td {
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.interactive {
    color: $pink;
    cursor: pointer;
}

.svg-logo {

    mask,
    path {
        fill: #ffffff;
    }
}

.nav-team--content {
    color: #fff;
    background: $nav-background-hover;

    a {
        color: #fff;

        .first_name {
            opacity: 0.6;
        }

        &:hover {
            color: $secondary;
        }

        &.active {
            color: $secondary;
        }
    }
}

.navbar {
    // background: $nav-background !important;
    // background: transparent !important;

    // box-shadow: 0 0 10px rgba(0,0,0,1);
    // border-bottom: 2px solid #FF02D0;
    // box-shadow: 0px 0px 23px rgba(0, 163, 255, 0.8);
    // -webkit-font-smoothing: antialiased;
    // animation: breathing 4s ease-out infinite normal;

    .mat-input-element {
        color: #fff;
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        color: rgba(255, 255, 255, 1);
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .mat-form-field-ripple,
    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: rgba(255, 255, 255, 1);
    }
}

@keyframes breathing {
    0% {
        box-shadow: 0px 0px 4px rgba(0, 163, 255, 0.8);
    }

    10% {
        box-shadow: 0px 0px 8px rgba(0, 163, 255, 0.8);
    }

    20% {
        box-shadow: 0px 0px 12px rgba(0, 163, 255, 0.8);
    }

    30% {
        box-shadow: 0px 0px 16px rgba(0, 163, 255, 0.8);
    }

    40% {
        box-shadow: 0px 0px 20px rgba(0, 163, 255, 0.8);
    }

    50% {
        box-shadow: 0px 0px 24px rgba(0, 163, 255, 0.8);
    }

    60% {
        box-shadow: 0px 0px 20px rgba(0, 163, 255, 0.8);
    }

    70% {
        box-shadow: 0px 0px 16px rgba(0, 163, 255, 0.8);
    }

    80% {
        box-shadow: 0px 0px 12px rgba(0, 163, 255, 0.8);
    }

    90% {
        box-shadow: 0px 0px 8px rgba(0, 163, 255, 0.8);
    }

    100% {
        box-shadow: 0px 0px 4px rgba(0, 163, 255, 0.8);
    }


}


.navbar-nav {
    a.nav-link {
        // color: $secondary-light;
        color: #fff;

        mat-icon {
            opacity: 0.5;
        }

        &.active,
        &:hover {
            background: rgba(#fff, 0.2);
            // color: $secondary-light;
        }
    }

    .dropdown-menu {
        background: $oxford-blue;
        //   color: #fff;
        border: none;
        box-shadow: 0 3px 4px rgba(#333, 0.3);

        a {
            // color: #fff;
            background: $oxford-blue;

            &:hover,
            &.active {
                color: #fff;
                background: linear-gradient(180deg, rgba(154, 146, 220, 0.16) 0%, rgba(218, 216, 234, 0.0752) 50%, rgba(55, 45, 114, 0.16) 100%);
                // color: #fff;
            }
        }
    }

    .nav-item {
        &:hover {
            a.nav-link {
                background: rgba(255, 255, 255, 0.2);
                // color: #fff;
            }
        }
    }

    .mat-menu-item {
        line-height: 30px;
        height: 30px;
        color: #677585 !important;
        font-weight: 600;
    }
}

// .navbar-nav {
//     a.nav-link {
//       mat-icon {
//         opacity: 0.5;
//       }
//       &.active {
//         background: rgba(0,0,0,0.2);
//       }
//     }
//     .dropdown-menu {
//       background: $nav-background-hover;
//       color: #fff;
//       box-shadow: 0 2px 5px rgba(0,0,0,0.3);
//       a {
//         color: #fff;
//         &:hover, &.active {
//           background: $nav-background;
//         }
//       }
//     }
//     .nav-item {
//       &:hover {
//         a.nav-link {
//           background: $nav-background-hover;
//         }
//       }
//     }
//   }

//   .pagination {
//     a.page-link {
//         color: rgba(255, 255, 255, 0.5);
//         &:before {
//           background: #fff;
//         }
//         &:hover {
//             color: #fff;
//         }
//     }
//     .active {
//         a.page-link {
//             color: $secondary;
//             &:before {
//                 background: $secondary;
//             }
//         }
//     }
//     .disabled {
//         a.page-link {
//             color: rgba(255, 255, 255, 0.3);
//         }
//     }
// }



.card-event {
    table.table {
        thead {
            tr {
                background: none;
            }

            th {
                padding: 2px 5px;
            }
        }
    }
}

.card-eventtype-participants {
    line-height: 16px;
    // color: rgba(255, 255, 255, 0.5);
}

.bodymap-light {
    display: none;
}

.copy-bg {
    background: rgba($secondary, 0.2);
}

.dropzone {
    .dz-message {
        color: #ffffff !important;
    }
}

.list-group-item {
    background-color: $card;
    border: 1px solid rgba(255, 255, 255, 0.7);
}

.player-card {
    background: #062b46;
    background: linear-gradient(to bottom, #0b4167, #051f36);
}

.player-number {
    color: #0c4f7a;
}

.player-index-bar {
    &.gray {
        background-color: #808080;
    }

    &.red {
        background-color: #fe5b58;
    }

    &.orange {
        background-color: #fea331;
    }

    &.yellow {
        background-color: #ffff00;
    }

    &.green {
        background-color: #00fe9c;
    }
}

.current-class {
    background-color: #2FB35B;
}

.tab-link {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.tab-link.active {
    border-bottom: 2px solid #2c506e;
    ;
}

.player-index-modal {
    background: linear-gradient(to bottom, #063554, #031e34);

    .number {
        color: #12699e;
    }
}

.fade-block {
    background-color: #042037;
}

.radial-chart .percentage {
    // fill: #fff;
}

.tab-link.opened {
    border-bottom: 2px solid #fcfcfd;
    ;
}

.tabs-header {
    background: #0a4973;
}

.route-btn {
    background-color: #6e818f;
    color: #fff;
}

.tabs-block {
    background: linear-gradient(to bottom, #083e60, #042036);
}

.legend {
    fill: #fff !important;
}

.timeline-primary {
    color: #909090;
}

.pitch-primary {
    color: #64aaf5;
    background-color: #64aaf5;
}

.pitch-secondary {
    color: #f94b47;
    background-color: #f94b47;
}

.hub-text {
    color: #ffffff;
}

.scroll {
    background-color: rgba(0, 0, 0, 0.5);
}

.mat-button-toggle-appearance-standard {
    background-color: transparent;
}

.mat-button-toggle-checked {
    background-color: #03A9F4;
}

.hover {
    &:hover {

        &.hover-underline,
        .hover-underline {
            box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
        }
    }
}

.hub-wp {
    background: url('/assets/manager-wallpaper.png');
    background-attachment: fixed;
    background-size: cover;
}

.groups-block {
    background: radial-gradient(#000, transparent);
}

.diagnostics-semi-transparent {
    background: linear-gradient(0deg, rgba(29, 53, 73, 0) 0%, rgba(29, 53, 73, 0.7) 20%, rgba(29, 53, 73, 0.7) 80%, rgba(29, 53, 73, 0) 100%);
}

.divider {
    background-color: #fff;
}


.manager-nav-btn {
    height: 40px;
    width: 200px;
    padding: 8px 15px 8px 15px;
    background: transparent;
    border: 1px solid #dddddd;
    cursor: pointer;
    outline: none;
    border-radius: 4px;

    color: #ffffff;
}

.manager-nav-btn:hover {
    background-color: #03A9F4;
    border: none;
}

.manager-nav-btn-active {
    background-color: #03A9F4;
    border: none;
}

.text-shadow {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}

.post-container {
    .square {
        background-color: #2c506e;
        box-shadow: 0 1px 1px #142a3a;

        &:hover {
            box-shadow: 0 10px 25px #00000044;
        }
    }
}


.ck-editor__editable {
    background: #1d3549 !important;
}