/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
    height: 100%;
}


@import "./app/ui/scss/variables";
@import './app/ui/scss/fonts';
@import '@angular/material/theming';
@import './app/ui/scss/mat-theme';

@import './app/ui/scss/core';
@import './app/ui/scss/table';
@import './app/ui/scss/tags';
@import './app/ui/scss/pagination';
@import './app/ui/scss/utilities';
@import './app/ui/scss/print';
@import './app/ui/scss/responsive.mixin';



@import './assets/lightning-admin';


@import '@glidejs/glide/src/assets/sass/glide.core';
@import '@glidejs/glide/src/assets/sass/glide.theme';

mat-dialog-container {
    columns: #fff;
}

.dark .mat-expansion-panel {
    background: linear-gradient(180deg, rgba(154, 146, 220, 0.16) 0%, rgba(218, 216, 234, 0.0752) 50%, rgba(55, 45, 114, 0.16) 100%);
    border: 1px solid rgba(237, 242, 255, 0.1);
    border-radius: 8px;
}

.customize+* .mat-menu-panel {
    min-width: 180px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    background: #08051F;
    border-radius: 4px;
    margin: 0;
    padding: 0;

    .mat-menu-content {
        padding: 0;
    }

    .mat-menu-item {
        font-size: 12px;

        align-items: center;
        color: #9F8DD0;
        white-space: nowrap;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        display: flex;

        i {
            font-size: 1.4em;
        }

        &:hover {
            background-color: #8080801a;
        }
    }

    .mat-menu-item[disabled] {
        color: #363160;
    }

}

[resizable] {
    position: relative;
    overflow: hidden;
}

[resizable]:after {
    content: "";
    position: absolute;
    top: 0;
    left: -5px;
    bottom: 0;
    width: 10px;
    cursor: ew-resize;
    background: #ddd;
}


.start-pop-up {
    background-color: #5f5797;
    opacity: 0.4 !important;
    z-index: 9;
}

.mdc-dialog__surface {
    background-color: #08051F !important;
    padding: 16px;
}

.mat-mdc-menu-panel {
    background-color: #08051F !important;
    color: #b6b2d4 !important;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
    font-family: 'Poppins' !important;
}