@media print {

    .print-none {
        display: none !important;
    }

    .print-only {
        display: block;
    }

    .print-only-flex {
        display: flex;
    }

    .print-only-cell {
        display: table-cell;
    }

    .print-only-inline {
        display: inline;
    }

    .dark .mat-app-background,
    .light .mat-app-background {
        background-color: #ffffff;
    }

    .uni-container {
        padding: 0;
    }


    .print-header {
        display: flex;
        // background-color: white;
        color: #333333;
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 20px;
        text-align: center;
        border-bottom: 1px solid #dddddd;
        // justify-content: space-between;

        div {
            flex: 1;
        }
    }


    .avoid-break-inside {
        page-break-inside: avoid;
    }

    .dark mat-chip.mat-small-chip,
    .dark .mat-small-chip,
    .light mat-chip.mat-small-chip,
    .light .mat-small-chip {
        min-height: 17px;
        padding: 2px 6px 2px;
    }

    table.table {
        th {
            padding: 0.125rem 0.25rem;
        }

        td {
            padding: 0.125rem 0.25rem;
            color: #1D3549;

            &.interactive {
                color: #1D3549 !important;
                font-weight: inherit !important;
            }
        }

        tr.fixed-height-31 {
            height: 25px !important;
        }
    }

}


@media screen {

    .print-only,
    .print-only-cell,
    .print-only-inline,
    .print-only-flex {
        display: none;
    }

}